import { Route } from '@angular/router';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { CallbackComponent } from './modules/callback/callback.component';
import { LandingComponent } from './modules/landing/landing.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        component: CallbackComponent,
        loadChildren: () =>
            import('app/modules/callback/callback.module').then(
                (m) => m.CallbackModule
            ),
    },

    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LandingComponent,
        loadChildren: () =>
            import('app/modules/landing/landing.module').then(
                (m) => m.LandingModule
            ),
    },
    {
        path: 'general/configs',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/dashboards/config/config.module').then(
                        (m) => m.ConfigModule
                    ),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
    },
];
