export const environment = {
    production: false,
    routes: {
        auth0: {
            redirectUri: 'https://media.repsense.io/signed-in-redirect',
            clientId: 'Ba5kFN3TIOO0Gv3yenJIiqsoPyA733uc',
            domain: 'repsense-dev.eu.auth0.com',
            audience: 'https://api-dev.repsense.io',
        },
        authentication: {
            login: 'https://api-dev.repsense.io/authentication/login',
            authenticate: 'https://api-dev.repsense.io/authentication',
        },
        users: {
            logUserLogin: 'https://api-dev.repsense.io/users/logUserLogin',
        },
        powerBiReport: {
            getPowerBiEmbedReportInfo:
                'https://api-dev.repsense.io/users/powerBiEmbedReportInfo',
        },
        api: {
            endpoint: 'https://api-dev.repsense.io',
        },
    },
};

// export const environment = {
//     production: false,
//     routes: {
//         auth0: {
//             redirectUri: 'http://localhost:4200/signed-in-redirect',
//             clientId: 'bxVqBEac4OvVqiyr3hlzbejZXSEXGGQX',
//             domain: 'repsense.eu.auth0.com',
//             audience: 'https://api.repsense.io',
//         },
//         authentication: {
//             login: 'http://localhost:3001/authentication/login',
//             authenticate: 'http://localhost:3001/authentication',
//         },
//         users: {
//             logUserLogin: 'http://localhost:3001/users/logUserLogin',
//         },
//         powerBiReport: {
//             getPowerBiEmbedReportInfo:
//                 'http://localhost:3001/users/powerBiEmbedReportInfo',
//         },
//         api: {
//             endpoint: 'http://localhost:3001',
//         },
//     },
// };

// export const environment = {
//     production: true,
//     routes: {
//         auth0: {
//             redirectUri: 'https://app.repsense.io/signed-in-redirect',
//             clientId: 'UYeUOuMSyIPwqUPTt9JHFuUkFJdnliqh',
//             domain: 'auth.repsense.io',
//             audience: 'https://api-prod.repsense.io',
//         },
//         authentication: {
//             login: 'https://api.repsense.io/authentication/login',
//             authenticate: 'https://api.repsense.io/authentication',
//         },
//         users: {
//             logUserLogin: 'https://api.repsense.io/users/logUserLogin',
//         },
//         powerBiReport: {
//             getPowerBiEmbedReportInfo:
//                 'https://api.repsense.io/users/powerBiEmbedReportInfo',
//         },
//         api: {
//             endpoint: 'https://api.repsense.io',
//         },
//     },
// };
