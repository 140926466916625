export enum AccessRoles {
    'full-access' = 'full-access',
    'single-report' = 'single-report',
    'admin-access' = 'admin-access',
}

export enum UserType {
    REGULAR = 'regular',
    TRIAL = 'trial',
    INTERNAL = 'internal',
}

export interface IAccessToken {
    email: string;
    role: AccessRoles;
    sub: string;
    type: UserType;
}
