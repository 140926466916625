import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IconsModule } from 'app/core/icons/icons.module';
import {
    AuthModule as Auth0Module,
    AuthHttpInterceptor,
} from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { environment } from 'environments/environment';

@NgModule({
    imports: [
        IconsModule,
        TranslocoCoreModule,
        Auth0Module.forRoot({
            domain: environment.routes.auth0.domain,
            clientId: environment.routes.auth0.clientId,
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
            authorizationParams: {
                redirect_uri: environment.routes.auth0.redirectUri,
                audience: environment.routes.auth0.audience,
            },
            httpInterceptor: {
                allowedList: [
                    {
                        uri: `${environment.routes.api.endpoint}/*`,
                        tokenOptions: {
                            authorizationParams: {
                                audience: environment.routes.auth0.audience,
                            },
                        },
                    },
                ],
            },
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error(
                'CoreModule has already been loaded. Import this module in the AppModule only.'
            );
        }
    }
}
