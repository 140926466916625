import { Injectable } from '@angular/core';
import { from, map } from 'rxjs';
import { assign, cloneDeep } from 'lodash-es';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import {
    workspaces as workspacesData,
    industries as industriesData,
    tags as tagsData,
} from 'app/mock-api/apps/workspaces/data';

@Injectable({
    providedIn: 'root',
})
export class WorkspacesMockApi {
    private _workspaces: any[] = workspacesData;
    private _industries: any[] = industriesData;
    private _tags: any[] = tagsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Workspaces - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/apps/workspaces/all').reply(() => {
            // Clone the workspaces
            const workspaces = cloneDeep(this._workspaces);

            // Sort the workspaces by the name field by default
            workspaces.sort((a, b) => a.name.localeCompare(b.name));

            // Return the response
            return [200, workspaces];
        });

        // -----------------------------------------------------------------------------------------------------
        // @ Workspaces Search - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/workspaces/search')
            .reply(({ request }) => {
                // Get the search query
                const query = request.params.get('query');

                // Clone the workspaces
                let workspaces = cloneDeep(this._workspaces);

                // If the query exists...
                if (query) {
                    // Filter the workspaces
                    workspaces = workspaces.filter(
                        (workspace) =>
                            workspace.name &&
                            workspace.name
                                .toLowerCase()
                                .includes(query.toLowerCase())
                    );
                }

                // Sort the workspaces by the name field by default
                workspaces.sort((a, b) => a.name.localeCompare(b.name));

                // Return the response
                return [200, workspaces];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Workspace - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/workspaces/workspace')
            .reply(({ request }) => {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the workspaces
                const workspaces = cloneDeep(this._workspaces);

                // Find the workspace
                const workspace = workspaces.find((item) => item.id === id);

                // Return the response
                return [200, workspace];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Workspace - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/workspaces/workspace')
            .reply(() => {
                // Generate a new workspace
                const newWorkspace = {
                    id: FuseMockApiUtils.guid(),
                    avatar: null,
                    name: 'New Workspace',
                    emails: [],
                    phoneNumbers: [],
                    job: {
                        title: '',
                        company: '',
                    },
                    birthday: null,
                    address: null,
                    notes: null,
                    tags: [],
                };

                // Unshift the new workspace
                this._workspaces.unshift(newWorkspace);

                // Return the response
                return [200, newWorkspace];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Workspace - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/workspaces/workspace')
            .reply(({ request }) => {
                // Get the id and workspace
                const id = request.body.id;
                const workspace = cloneDeep(request.body.contact);

                // Prepare the updated workspace
                let updatedWorkspace = null;

                // Find the workspace and update it
                this._workspaces.forEach((item, index, workspaces) => {
                    if (item.id === id) {
                        // Update the workspace
                        workspaces[index] = assign(
                            {},
                            workspaces[index],
                            workspace
                        );

                        // Store the updated workspace
                        updatedWorkspace = workspaces[index];
                    }
                });

                // Return the response
                return [200, updatedWorkspace];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Workspace - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/workspaces/workspace')
            .reply(({ request }) => {
                // Get the id
                const id = request.params.get('id');

                // Find the workspace and delete it
                this._workspaces.forEach((item, index) => {
                    if (item.id === id) {
                        this._workspaces.splice(index, 1);
                    }
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Industries - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/workspaces/industries')
            .reply(() => [200, cloneDeep(this._industries)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/workspaces/tags')
            .reply(() => [200, cloneDeep(this._tags)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/workspaces/tag')
            .reply(({ request }) => {
                // Get the tag
                const newTag = cloneDeep(request.body.tag);

                // Generate a new GUID
                newTag.id = FuseMockApiUtils.guid();

                // Unshift the new tag
                this._tags.unshift(newTag);

                // Return the response
                return [200, newTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/workspaces/tag')
            .reply(({ request }) => {
                // Get the id and tag
                const id = request.body.id;
                const tag = cloneDeep(request.body.tag);

                // Prepare the updated tag
                let updatedTag = null;

                // Find the tag and update it
                this._tags.forEach((item, index, tags) => {
                    if (item.id === id) {
                        // Update the tag
                        tags[index] = assign({}, tags[index], tag);

                        // Store the updated tag
                        updatedTag = tags[index];
                    }
                });

                // Return the response
                return [200, updatedTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tag - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/workspaces/tag')
            .reply(({ request }) => {
                // Get the id
                const id = request.params.get('id');

                // Find the tag and delete it
                this._tags.forEach((item, index) => {
                    if (item.id === id) {
                        this._tags.splice(index, 1);
                    }
                });

                // Get the contacts that have the tag
                const workspacesWithTag = this._workspaces.filter(
                    (workspace) => workspace.tags.indexOf(id) > -1
                );

                // Iterate through them and delete the tag
                workspacesWithTag.forEach((workspace) => {
                    workspace.tags.splice(workspace.tags.indexOf(id), 1);
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Avatar - POST
        // -----------------------------------------------------------------------------------------------------

        /**
         * Read the given file as mock-api url
         *
         * @param file
         */
        const readAsDataURL = (file: File): Promise<any> =>
            // Return a new promise
            new Promise((resolve, reject) => {
                // Create a new reader
                const reader = new FileReader();

                // Resolve the promise on success
                reader.onload = (): void => {
                    resolve(reader.result);
                };

                // Reject the promise on error
                reader.onerror = (e): void => {
                    reject(e);
                };

                // Read the file as the
                reader.readAsDataURL(file);
            });
        this._fuseMockApiService
            .onPost('api/apps/workspaces/avatar')
            .reply(({ request }) => {
                // Get the id and avatar
                const id = request.body.id;
                const avatar = request.body.avatar;

                // Prepare the updated contact
                let updatedWorkspace: any = null;

                // In a real world application, this would return the path
                // of the saved image file (from host, S3 bucket, etc.) but,
                // for the sake of the demo, we encode the image to base64
                // and return it as the new path of the uploaded image since
                // the src attribute of the img tag works with both image urls
                // and encoded images.
                return from(readAsDataURL(avatar)).pipe(
                    map((path) => {
                        // Find the workspace and update it
                        this._workspaces.forEach((item, index, workspaces) => {
                            if (item.id === id) {
                                // Update the avatar
                                workspaces[index].avatar = path;

                                // Store the updated workspace
                                updatedWorkspace = workspaces[index];
                            }
                        });

                        // Return the response
                        return [200, updatedWorkspace];
                    })
                );
            });
    }
}
