import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { FuseSplashScreenService } from '@fuse/services/splash-screen';

@Component({
    selector: 'landing',
    templateUrl: './landing.component.html',
})
export class LandingComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private splashScreenService: FuseSplashScreenService) {}

    ngOnInit(): void {
        this.splashScreenService.show();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.splashScreenService.hide();

        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
