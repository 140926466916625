import { FuseNavigationItem } from '@fuse/components/navigation';
import { AccessRoles } from 'app/core/auth/auth.types';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'overview',
        type: 'group',
        accessRoles: [
            AccessRoles['single-report'],
            AccessRoles['full-access'],
            AccessRoles['admin-access'],
        ],
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:view-grid',
                link: '/general/configs/ReportSectionaf6267ccbabb05ed7610',
            },
        ],
    },
    {
        id: 'interest',
        title: 'Interest',
        type: 'group',
        accessRoles: [
            AccessRoles['single-report'],
            AccessRoles['full-access'],
            AccessRoles['admin-access'],
        ],
        children: [
            {
                id: 'general.shareofsearch',
                title: 'Share of Search',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/general/configs/ReportSection04c1bbccdef18b8d8343',
            },
            {
                id: 'general.discovery',
                title: 'Discovery',
                type: 'basic',
                icon: 'heroicons_outline:magnifying-glass-circle',
                link: '/general/configs/ReportSectioncd8f00f108c0b166a960',
            },
            {
                id: 'general.googlenews',
                title: 'Google News',
                type: 'basic',
                icon: 'heroicons_outline:newspaper',
                link: '/general/configs/ReportSection1891b469714a1329f2ab',
            },
        ],
    },
    {
        id: 'signalanalytics',
        title: 'Signal Analytics',
        type: 'group',
        accessRoles: [
            AccessRoles['single-report'],
            AccessRoles['full-access'],
            AccessRoles['admin-access'],
        ],
        children: [
            {
                id: 'general.factors',
                title: 'Factors',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar-square',
                link: '/general/configs/ReportSection308785935e7eea60a0b3',
            },
            {
                id: 'general.insights',
                title: 'Insights',
                type: 'basic',
                icon: 'heroicons_outline:light-bulb',
                link: '/general/configs/ReportSectionf2587922a9e07c100808',
            },
            {
                id: 'general.industry',
                title: 'Industry',
                type: 'basic',
                icon: 'heroicons_outline:beaker',
                link: '/general/configs/ReportSectionef6d4c4ea3dc9126e10b',
            },
        ],
    },
    {
        id: 'searchresults',
        title: 'Search Results',
        type: 'group',
        accessRoles: [
            AccessRoles['single-report'],
            AccessRoles['full-access'],
            AccessRoles['admin-access'],
        ],
        children: [
            {
                id: 'general.search',
                title: 'Search',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: '/general/configs/ReportSectionda5d69e450692316b0d3',
            },
            {
                id: 'general.herocontent',
                title: 'Hero Content',
                type: 'basic',
                icon: 'heroicons_outline:bolt',
                link: '/general/configs/ReportSection30a2470edeec3d7962ef',
            },
            {
                id: 'general.reviews',
                title: 'Reviews',
                type: 'basic',
                icon: 'heroicons_outline:star',
                link: '/general/configs/ReportSection73207e61c5efe4e5c045',
            },
        ],
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'group',
        accessRoles: [AccessRoles['admin-access']],
        children: [
            {
                id: 'general.admin',
                title: 'Choose Industry',
                type: 'basic',
                icon: 'heroicons_outline:shield-check',
                link: '/general/configs/ReportSection',
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
