import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChild,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
    /**
     * Constructor
     */
    constructor(
        private auth0Service: Auth0Service,
        private httpClient: HttpClient,
        private userService: UserService
    ) {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        _childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this._check(state);
    }

    canActivate(
        _childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this._check(state);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(state: RouterStateSnapshot): Observable<boolean> {
        return this.auth0Service.isAuthenticated$.pipe(
            switchMap((loggedIn) => {
                if (!loggedIn) {
                    this.auth0Service.loginWithRedirect({
                        appState: { target: state.url },
                    });
                    return of(false);
                } else if (!this.userService.user) {
                    return this.httpClient
                        .get(environment.routes.authentication.authenticate)
                        .pipe(
                            catchError(() => {
                                this.auth0Service.logout();
                                return of(false);
                            }),
                            switchMap((response: User) => {
                                this.userService.user = response;
                                return of(true);
                            })
                        );
                }

                return of(true);
            })
        );
    }
}
