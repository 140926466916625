import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { concatMap, from, catchError, of, Subject, takeUntil } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from 'environments/environment';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';

@Component({
    selector: 'callback',
    templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private httpClient: HttpClient,
        private auth0Service: Auth0Service,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private splashScreenService: FuseSplashScreenService
    ) {}

    ngOnInit(): void {
        this.splashScreenService.show();

        this.activatedRoute.queryParams
            .pipe(
                takeUntil(this._unsubscribeAll),
                concatMap((params) => {
                    if (params.code && params.state) {
                        return this.auth0Service.user$.pipe(
                            concatMap((user) => {
                                return this.httpClient
                                    .post(
                                        environment.routes.users.logUserLogin,
                                        { email: user.email }
                                    )
                                    .pipe(
                                        concatMap((user: User) => {
                                            this.userService.user = user;
                                            return from(
                                                this.router.navigate([
                                                    'general/configs/ReportSectionaf6267ccbabb05ed7610',
                                                ])
                                            ).pipe(
                                                concatMap(() => {
                                                    return of(null);
                                                })
                                            );
                                        }),
                                        catchError(() => {
                                            return this.auth0Service.logout();
                                        })
                                    );
                            })
                        );
                    }
                    return from(
                        this.router.navigate([
                            'general/configs/ReportSectionaf6267ccbabb05ed7610',
                        ])
                    ).pipe(concatMap(() => of(null)));
                })
            )
            .subscribe(() => {
                this.splashScreenService.hide();
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
